import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { getConfiguration } from "../services/commonService";
import { useToast } from "../hooks/useToast";
import { ToastTypesList } from '../utils/constants';
import { Response } from "../types/common";

export type ConfigData = {
    key: string,
    value: string,
}

type MinAndMaxBirthdateYears = {
    maxYear: number,
    minYear: number,
}

interface ConfigResponse {
    type: string;
    values: ConfigData[];
}

export interface ConfigContextProps {
    countryCodes?: ConfigData[],
    genders?: ConfigData[],
    minAndMaxBirthdateYears?: MinAndMaxBirthdateYears
}

const defaulValues: ConfigContextProps = {
    countryCodes: [],
    genders: [],
    minAndMaxBirthdateYears: {
        minYear: new Date().getFullYear() - 100,
        maxYear: new Date().getFullYear() - 18,
    }
}

export const ConfigContext = createContext(defaulValues);

export const ConfigProvider = ({ children }: PropsWithChildren) => {

    const [countryCodes, setCountryCodes] = useState<ConfigData[]>();
    const [genders, setGenders] = useState<ConfigData[]>();
    const [minAndMaxBirthdateYears, setMinAndMaxBirthdateYears] = useState<MinAndMaxBirthdateYears>();

    const { showToast } = useToast();

    useEffect(() => {
        const fetchConfigs = async () => {
            const configResponse: Response<ConfigResponse[]> = await getConfiguration();
            if (configResponse.successful) {
                setCountryCodes(configResponse.data?.find(e => e.type === 'phone_code')?.values)
                setGenders(configResponse.data?.find(e => e.type === 'gender')?.values);

                const minAndMaxUserAges = configResponse.data.find(e => e.type === 'age')!.values
                const currentYear = new Date().getFullYear();
                const maxYear = currentYear - Number(minAndMaxUserAges.find(e => e.key === 'min')!.value);
                const minYear = currentYear - Number(minAndMaxUserAges.find(e => e.key === 'max')!.value);
                setMinAndMaxBirthdateYears({ maxYear, minYear });

            } else {
                console.error('error al cargar configuraciones', configResponse.errors)
                showToast(ToastTypesList.ERROR, configResponse.message);
            }
        }

        fetchConfigs();
    }, []);

    return (
        <ConfigContext.Provider value={{ countryCodes, minAndMaxBirthdateYears, genders }}>
            {children}
        </ConfigContext.Provider>
    )
}