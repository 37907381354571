import React, { useEffect, useRef, useState } from 'react';
import IconExclamartionMark from './icons/IconExclamartionMark';
import copys from '../utils/copys';
import { useConfigContext } from '../hooks/useConfigContext';


const changeLabelChildrenStyles = (labelRef:HTMLDivElement, isFocused:boolean) => {
  if (labelRef && labelRef.children) {
    Array.from(labelRef.children).forEach((child) => {
      const div = child as HTMLDivElement;
      if (isFocused) {
        div.style.fontSize = '12px';
        div.style.lineHeight = '16px';
      } else {
        div.style.fontSize = '16px';
        div.style.lineHeight = '24px';
      }
    });
  }
}

type CustomInputProps = {
  type?: React.HTMLInputTypeAttribute;
  defaultValue?: string;
  required?: boolean;
  label: string;
  onChange: (value: string) => void;
  regExValidate?: RegExp;
  errorMessage?: string;
  validateAge?: boolean;
  id: string;
  maxLength: number;
}

export const CustomInput = ({ type, defaultValue, required, label, onChange, regExValidate, errorMessage, validateAge, id, maxLength }: CustomInputProps) => {
  const inputDivRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLDivElement>(null)

  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [hasError, setHasError] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState(errorMessage);
  const { minAndMaxBirthdateYears } = useConfigContext();

  useEffect(() => {
    handleOnBlur();
  }, [])

  useEffect(() => {
    if (validateAge) {
      if (inputValue === '' || (Number(inputValue) <= minAndMaxBirthdateYears?.maxYear! && Number(inputValue) >= minAndMaxBirthdateYears?.minYear!)) {
        setHasError(false);
        setCustomErrorMessage(errorMessage)
      } else if (Number(inputValue) < minAndMaxBirthdateYears?.minYear!) {
        if (!hasError && inputValue.length === 4) {
          setCustomErrorMessage(copys.inputAgeErrorMessage100Years);
          setHasError(true);
        }
      } else {
        setCustomErrorMessage(errorMessage)
        setHasError(true);
      }
    }
  }, [inputValue, minAndMaxBirthdateYears])


  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    if (regExValidate) {
      if (regExValidate.test(value)) {
        setInputValue(value);
        onChange(value);
      }
    } else {
      setInputValue(value);
      onChange(value);
    }
  };

  const handleOnFocus = () => {
    inputDivRef.current!.style.display = 'flex';
    changeLabelChildrenStyles(labelRef.current!, true);
    inputRef.current!.focus();
  }

  const handleOnBlur = () => {
    if (inputValue === '') {
      inputDivRef.current!.style.display = 'none';
      changeLabelChildrenStyles(labelRef.current!, false);
    } else {
      inputDivRef.current!.style.display = 'flex';
    }
  }

  return (

    <div className='flex container flex-col items-center gap-1'>
      <div className="flex items-start container gap-1 justify-center">
        <div tabIndex={0} className={`rounded-lg border border-solid flex p-4 items-center justify-between gap-2 h-14 container basis-full min-[340px]:basis-11/12 focus-within:border-2 ${hasError ? 'border-border_danger' : 'border-border_primary'}`} onFocus={handleOnFocus} >
          <div className="flex items-center gap-2 justify-start">
            <div className="flex flex-col justify-center items-start">
              <div className="flex items-start gap-1 self-stretch" ref={labelRef}>
                <span className="text-white font-roboto text-base font-normal">
                  {label}
                </span>
                {
                  required && (
                    <span className="text-text_secondary font-roboto font-normal text-base">
                      *
                    </span>
                  )
                }
              </div>
              <div className="items-start gap-1 self-stretch hidden" ref={inputDivRef}>
                <input
                  id={id}
                  ref={inputRef}
                  value={inputValue}
                  onChange={handleChange}
                  type={type}
                  className='appearance-none text-white font-roboto text-base font-normal text-start'
                  onBlur={handleOnBlur}
                  maxLength={maxLength ? maxLength : 99999999}
                />
              </div>
            </div>
          </div>
          <div className={` w-6 h-6 shrink-0 ${hasError ? '' : 'invisible'}`}>
            <IconExclamartionMark fill='#FA7490' />
          </div>
        </div>
      </div>
      <div className="flex flex-col container items-center">
        <div id={`${id}_ErrorMessage`} className={`flex justify-center text-left w-full font-roboto font-normal text-xs ${hasError ? 'text-border_danger' : 'text-white'}`}>
          <div className="basis-full min-[340px]:basis-11/12">
            {customErrorMessage}
          </div>
        </div>
      </div>
    </div>
  );
}
