import { useState } from 'react'
import parse from 'html-react-parser'
import { useCollapse } from 'react-collapsed'

import { ReactComponent as TermsSvg } from '../assets/ilustraciones/terms.svg'
import { ReactComponent as ExpandMoreSvg } from '../assets/ilustraciones/expand_more.svg'
import { ReactComponent as ExpandLessSvg } from '../assets/ilustraciones/expand_less.svg'
import copys from '../utils/copys'

import * as gtmService from '../services/gtmService';

type DetailInfoCardProps = {
  html: string;
}
const DetailInfoCard = ({ html }: DetailInfoCardProps) => {

  const [isExpanded, setExpanded] = useState(false)

  const { getCollapseProps, getToggleProps } = useCollapse({
    collapsedHeight: 120,
    isExpanded
  });

  const toggleCollapse = () => {
    if (!isExpanded) {
      gtmService.pushClickButton(copys.buttonShowMore)
    }
    setExpanded((prevExpanded) => !prevExpanded);
  }

  return (
    <div className='flex flex-col p-3 items-start gap-2 rounded-xl bg-white'>
      <div className="flex items-center gap-2">
        <TermsSvg />
        <div className='text-black font-libre-franklin font-semibold text-xl'>
          {copys.generalConditionsPromo}
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div id='terms' className='font-roboto text-sm font-normal text-text_strong w-full px-7 pb-3'>
          {html && parse(html)}
        </div>
      </div>
      <div className='w-full flex justify-center'>
        <button {...getToggleProps({ onClick: () => toggleCollapse() })} className='text-center text-text_strong font-roboto text-base font-semibold'>
          <div className='flex items-center gap-1'>
            <div>
              {isExpanded ? copys.buttonShowLess : copys.buttonShowMore}
            </div>
            {isExpanded ? <ExpandLessSvg /> : <ExpandMoreSvg />}
          </div>
        </button>
      </div>
    </div>

  )
}

export default DetailInfoCard
