import { useCountDown } from '../hooks/useCountDown';

type CountdownTimerProps = {
    targetDate: string,
    onComplete: () => void;
}

const CountdownTimer = ({ targetDate, onComplete }: CountdownTimerProps) => {
    const { hours, minutes, seconds } = useCountDown(targetDate, onComplete);
    return <span>{hours}:{minutes}:{seconds}</span>
}

export default CountdownTimer
