import { SquareX } from 'lucide-react'
import React from 'react'
import IconLocationHome from '../icons/IconLocationHome'

const NoteAboutNoStores = () => {
    return (
        <div className='bg-white rounded-3xl p-10 pb-6 flex flex-col gap-10 w-full'>
            <div className='flex flex-col gap-2 text-[#070561] font-roboto'>
                <div className='font-semibold'>Descuentón no ha llegado a esta zona</div>
                <div className="flex gap-2">
                    <div>
                        <SquareX color='#B31634' size={24} />
                    </div>
                    <div className='text-sm'>Todavía no hemos llegado a tu ubicación, por favor intenta con otra dirección.</div>
                </div>
            </div>
            <div className="flex justify-center gap-[10px] px-6 py-5 rounded-xl bg-[#100E9D] cursor-pointer">
                <IconLocationHome size={24} />
                <div className="font-semibold text-white">Ingresar otra dirección</div>
            </div>
        </div>
    )
}

export default NoteAboutNoStores
