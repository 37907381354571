import React, { useState, useEffect } from 'react';
import OtpInputLibrary from 'react-otp-input';
import { HandleOtpChangeParam } from '../types/common';

const inputClassName = 'flex flex-col items-start border border-solid w-[9px] h-[30px] p-4 gap-2 rounded bg-transparent_neutral text-white font-roboto text-base font-normal';

type OtpInputProps = {
    length: number,
    onChange: (val: HandleOtpChangeParam) => void,
    isOtpWithErrors: boolean,
    errorMessage: string,
};
const OtpInput = ({ length, onChange, isOtpWithErrors, errorMessage }: OtpInputProps) => {

    const [OTP, setOTP] = useState('');

    useEffect(() => {
        let ac = new AbortController();

        if ('OTPCredential' in window) {
            navigator.credentials.get({
                otp: { transport: ['sms'] },
                signal: ac.signal,
            }).then((otp) => {
                if (otp && otp.code) {
                    setOTP(otp.code);
                }
            }).catch((error) => {
                console.error(error);
            });

            return () => {
                ac.abort();
            };
        }
    }, []);

    useEffect(() => {
        onChange({ isComplete: OTP.length === 6, otp: OTP })
    }, [OTP])

    return (
        <div className='container flex flex-col items-center'>
            <OtpInputLibrary
                value={OTP}
                onChange={setOTP}
                numInputs={length}
                skipDefaultStyles
                inputType='number'
                renderInput={(props, inputIndex) => <input {...props} autoComplete='one-time-code' id={`otpInput_${inputIndex + 1}`} />}
                containerStyle={'flex justify-between items-center w-full min-[350px]:w-[90%] min-[400px]:w-[85%] min-[470px]:w-[350px]'}
                inputStyle={inputClassName + (isOtpWithErrors && ' border-border_danger')}
            />
            {
                isOtpWithErrors && (
                    <div className='container flex flex-col items-center'>
                        <div className="w-full min-[340px]:w-[90%] min-[400px]:w-[85%] min-[470px]:w-[350px] font-roboto font-normal text-xs text-left text-border_danger" id='otpErrorMessage'>
                            {errorMessage}
                        </div>
                    </div>

                )
            }

        </div>

    )
}

export default OtpInput
