import { useCallback, useEffect, useRef, useState } from "react";
import { getPromos } from "../services/promosService";
import Card from "../components/carousel/Card";
import copys from "../utils/copys";
import ScreenLoading from "./ScreenLoading";
import { useAuthContext } from "../hooks/useAuthContext";
import * as gtmService from "../services/gtmService";
import { usePromoContext } from "../hooks/usePromoContext";
import { useToast } from "../hooks/useToast";
import { ToastTypesList } from "../utils/constants";
import * as Sentry from "@sentry/react";
import { promoContextDefaultValues } from "../context/PromoContext";
import { PromotionItem } from "../models/promotions";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import LocationSection from "../components/screenHome/LocationSection";
import Tabs, { TabsOptions } from "../components/screenHome/Tabs";
import { useLocationContext } from "../hooks/useLocationContext";
import DisclaimerLogin from "../components/screenHome/DisclaimerLogin";
import DisclaimerLocation from "../components/screenHome/DisclaimerLocation";
import {
  GoogleMap,
  useLoadScript,
  Libraries,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { getStoresNearBy } from "../services/authService/authService";
import { List, Map } from "lucide-react";
import { LocationUserData, Store } from "../models/location";
import { ReactComponent as MapIcon } from "../assets/icons/map-lg.svg";
import { ScreenStoreAvailable } from "./ScreenStoreAvailable";
import StoresFilter from "../components/screenHome/StoresFilter";
import { useNavigate } from "react-router-dom";
import NoteAboutNoStores from "../components/screenHome/NoteAboutNoStores";
import { StoreView } from "../context/LocationContext";

export default function ScreenHome() {
  const [promos, setPromos] = useState<PromotionItem[]>([]);
  const [showLoading, setShowLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<TabsOptions>("promotions");
  const [storeView, setStoreView] = useState<StoreView>("map");
  const [stores, setStores] = useState<Store[]>([]);
  const [showDisclaimerLocation, setShowDisclaimerLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useAuthContext();
  const { setPromoData } = usePromoContext();
  const {
    locationUserData,
    isLocationInputFocused,
    setIsLocationInputFocused,
    selectedStoreFilters,
    setSelectedStoreFilters,
    setHomePagePreviousState,
    homePagePreviousState,
  } = useLocationContext();
  const { showToast } = useToast();
  const navigate = useNavigate();

  const hasPollingStoppedRef = useRef(false);
  const selectedTabRef = useRef<TabsOptions>("promotions");

  const [libraries] = useState<Libraries>(["places"]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries,
  });

  const locationUserDataRef = useRef<LocationUserData>(locationUserData);

  const navigateToStoreDetail = (store:Store)=>{
    setHomePagePreviousState({
      storeView,
      selectedStoreFilters,
    })
    navigate(
      `store/details/${store.shopkeeper_id}?lat=${locationUserData.location?.latitude}&lng=${locationUserData.location?.longitude}`
    )
  }

  useEffect(() => {
    locationUserDataRef.current = locationUserData;
  }, [locationUserData]);

  useEffect(() => {
    if (
      selectedTab === "stores" &&
      locationUserData.locationType !== "none" &&
      storeView === "map"
    ) {
      setIsLoading(true)
      getStoresNearBy(
        locationUserData.location?.latitude.toString()!,
        locationUserData.location?.longitude.toString()!,
        selectedStoreFilters
      ).then((res) => {
        if (res.successful) {
          setStores(res.data);
        } else {
          setStores([]);
        }
      }).finally(() => setIsLoading(false));
    }

    if (selectedTab === "promotions") {
      loadPromos();
    }
  }, [selectedTab, locationUserData, selectedStoreFilters, storeView]);

  useEffect(() => {
    selectedTabRef.current = selectedTab;
  }, [selectedTab]);

  const center = {
    lat: locationUserData.location?.latitude!,
    lng: locationUserData.location?.longitude!,
  };

  const loadPromos = useCallback(async () => {
    setIsLoading(true);
    const { successful, data, errors, message } = await getPromos(
      userData.isLogged ? locationUserDataRef.current.location : null
    );
    setTimeout(() => setShowLoading(false), 500);
    if (successful) {
      setPromos(data);
    } else {
      console.log(errors);
      showToast(ToastTypesList.ERROR, copys.toastErrorMessageDefault);
      gtmService.pushNotification("error", message);
      Sentry.captureException(errors, {
        tags: {
          flow: "load_promotions",
          user_id: userData?.user?.id,
        },
      });
    }
    setIsLoading(false);
  }, [showToast, userData]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    setPromoData(promoContextDefaultValues.promoData);
    if(homePagePreviousState!==null){
      setSelectedTab('stores');
      setSelectedStoreFilters(homePagePreviousState.selectedStoreFilters);
      setStoreView(homePagePreviousState.storeView);
    }
    setHomePagePreviousState(null);

    if (userData.isLogged) {
      gtmService.pushPageView("/cliente-home", "Cliente: Home");
    } else {
      gtmService.pushPageView("/cliente-inicio", "Cliente: Inicio");
    }

    const startPolling = async () => {
      if (!hasPollingStoppedRef.current) {
        if (
          document.visibilityState === "visible" &&
          selectedTabRef.current === "promotions"
        ) {
          await loadPromos();
        }
        timeoutId = setTimeout(startPolling, 10000); // Start next polling after 10 seconds
      }
    };

    startPolling(); // Start the initial fetch

    return () => {
      hasPollingStoppedRef.current = true;
      clearTimeout(timeoutId); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (locationUserData.locationType === "none") {
      setShowDisclaimerLocation(true);
    } else {
      setShowDisclaimerLocation(false);
    }
  }, [locationUserData]);

  return (
    <>
      <Header
        customHeader={
          isLocationInputFocused ? copys.headerWhenTypingLocation : undefined
        }
        backButtonCallback={() => setIsLocationInputFocused(false)}
      />
      <div className="flex flex-col items-center gap-4 pb-5 min-h-screen">
        {showLoading && <ScreenLoading isHome />}
        {userData.isLogged ? (
          <>
            {!isLocationInputFocused && (
              <div className="font-libre-franklin font-semibold text-xs text-[#070561] pt-5 pb-1">
                {copys.homeSlogan}
              </div>
            )}
            {isLoaded && <LocationSection />}
            {!isLocationInputFocused && showDisclaimerLocation && (
              <DisclaimerLocation />
            )}
          </>
        ) : (
          <DisclaimerLogin />
        )}

        {!isLocationInputFocused && (
          <>
            <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab === "promotions" ? (
              <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center items-center px-4 pb-28 gap-3">
                {promos?.length > 0 &&
                  promos?.map((promo) => {
                    return (
                      <Card
                        key={promo.id}
                        promo={promo}
                        status={promo.status}
                      />
                    );
                  })}
                {promos.length === 0 && !isLoading && (
                  <div className="pt-4">
                    <NoteAboutNoStores />
                  </div>
                )}
              </div>
            ) : (
              <>
                <>
                  <StoresFilter />
                </>
                {isLoaded && storeView === "map" && (
                  <GoogleMap
                    id="DESCUENTON_MAP"
                    mapContainerClassName="w-full h-[50vh] -mt-4 relative"
                    center={center}
                    zoom={14.3}
                    options={{
                      minZoom: 14.3,
                      mapTypeControl: false,
                      zoomControl: false,
                      fullscreenControl: false,
                      streetViewControl: false,
                    }}
                  >
                    <Circle
                      center={{
                        lat: locationUserData.location?.latitude!,
                        lng: locationUserData.location?.longitude!,
                      }}
                      radius={1000}
                      options={{ fillColor: "#76A8FF", strokeWeight: 0 }}
                    />
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker
                      position={{
                        lat: locationUserData.location?.latitude!,
                        lng: locationUserData.location?.longitude!,
                      }}
                    ></Marker>
                    <div className="fixed z-20 p-[10px] rounded-full cursor-pointer bottom-40 right-2 bg-[#D4E5FF] drop-shadow-xl">
                      <Map color="#070561" size={24} />
                    </div>
                    <div
                      className="fixed z-20 p-[10px] rounded-full cursor-pointer bottom-28 right-2 bg-[#F0F3F4] drop-shadow-xl"
                      onClick={() => setStoreView("list")}
                    >
                      <List color="#070561" size={24} />
                    </div>
                    {stores.length > 0 || isLoading ? (
                      stores.map((store) => (
                        <Marker
                          key={store.id}
                          position={{
                            lat: Number(store.latitude),
                            lng: Number(store.longitude),
                          }}
                          icon={{
                            url: "https://qa.descuentongepp.com/favicon.ico",
                          }}
                          onClick={() =>navigateToStoreDetail(store)}
                        />
                      ))
                    ) : (
                      <div className="absolute w-full h-full flex justify-center items-center">
                        <div className="flex justify-center items-center">
                          <div className="w-[80%] bg-white rounded-3xl p-10 flex flex-col gap-6 items-center text-[#070561]">
                            <div className="font-roboto font-semibold">
                              Por favor, ingresa otra dirección
                            </div>
                            <div className="flex gap-2">
                              <div>
                                <MapIcon />
                              </div>
                              <div className="font-roboto text-sm">
                                No hemos encontrado tiendas cerca a ti en{" "}
                                {
                                  locationUserData.location?.addressSelected
                                    .fullAddress
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </GoogleMap>
                )}
                {storeView === "list" && (
                  <div className="contenedor">
                    <ScreenStoreAvailable />
                    <div
                      className="fixed p-[10px] rounded-full cursor-pointer bottom-40 right-2 bg-[#F0F3F4] drop-shadow-xl"
                      onClick={() => setStoreView("map")}
                    >
                      <Map color="#070561" size={24} />
                    </div>
                    <div className="fixed p-[10px] rounded-full cursor-pointer bottom-28 right-2 bg-[#D4E5FF] drop-shadow-xl">
                      <List color="#070561" size={24} />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {userData.isLogged && !isLocationInputFocused && (
        <Footer setSelectedTab={setSelectedTab} />
      )}
    </>
  );
}
