import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../Button';
import { ReactComponent as FileClock } from '../../assets/icons/file-clock.svg';
import { ReactComponent as UserHand } from '../../assets/icons/user-hand.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';

const DisclaimerLogin = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-white rounded-3xl p-10 mt-4 flex flex-col gap-2 font-roboto text-[#070561]'>
      <div className='text-base font-semibold'>Obten beneficios iniciando sesión:</div>
      <div className="flex gap-2 items-center">
        <FileClock className='w-[25px]' />
        <div className='text-sm'>Seguimiento de tus ahorros</div>
      </div>
      <div className="flex gap-2 items-center">
        <UserHand />
        <div className='text-sm'>Acceso a todas las promociones</div>
      </div>
      <div className="flex gap-2 items-center">
        <Star />
        <div className='text-sm'>Canjes al instante</div>
      </div>
      <div onClick={() => navigate('/login')} className='flex justify-center items-center gap-[10px] mt-8 py-5 px-10 bg-[#100E9D] rounded-xl cursor-pointer'>
        <div className='text-white font-semibold'>
          Iniciar sesión
        </div>
      </div>
    </div>
  )
}

export default DisclaimerLogin
