type ProfileDataItemProps = {
  header: string,
  value: string,
  id: string,
}

const ProfileDataItem = ({ header, value, id }: ProfileDataItemProps) => {
  return (
    <div className='flex container flex-col justify-center items-start gap-1 pt-2 pl-4'>
      <div className="flex items-center gap-2">
        <div className="flex flex-col justify-center items-start">
          <div className="text-text_disabled font-libre-franklin text-xs font-semibold" id={`${id}_header`}>
            {header}
          </div>
          <div className="text-text_strong font-roboto text-base font-normal" id={`${id}_value`}>
            {value}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-start w-full">
        <div className="h-[1px] bg-neutral_weak w-full" />
      </div>
    </div>
  )
}

export default ProfileDataItem
