export const otpValidationStatus = {
  INCORRECT_OTP: "INCORRECT_OTP",
  EXPIRED_OTP: "EXPIRED_OTP",
  USER_NOT_REGISTERED: "user_not_found",
  PHONE_NOT_FOUND: "",
};

export enum ToastTypesList {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  SYSTEM = "SYSTEM",
  ERROR = "ERROR",
}

export const promoStatus = {
  AVAILABLE: 1,
  ACTIVE: 2,
  ONHOLD: 3,
  DISABLED: 4,
  EXPIRED: 5,
};

export const genders = {
  M: "Masculino",
  O: "Otro",
  F: "Femenino",
};

export enum TermsOrPrivacy {
  TERMS = "terms",
  PRIVACY = "privacy",
}

export const routePaths = {
  HOME: "home",
  DETAIL: "promo/:promoId",
  PROFILE: "profile",
  SIGNUP: "signup",
  LOGIN: "login",
  OTP: "otp",
  REGISTER: "register",
  TERMS: "terms/:type",
  STORE: "store",
  STORE_DETAILS: "store/details/:id",
};
