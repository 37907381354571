import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource-variable/libre-franklin';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'

import './output.css';

import { AuthProvider } from './context/AuthContext';
import { PromoProvider } from './context/PromoContext';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from './context/ConfigContext';
import { LocationProvider } from './context/LocationContext';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'https://dev.descuentongepp.com',
    'https://qa.descuentongepp.com',
    'https://descuentongepp.com',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Router>
    <ConfigProvider>
      <PromoProvider>
        <AuthProvider>
          <LocationProvider>
            <App />
          </LocationProvider>
        </AuthProvider>
      </PromoProvider>
    </ConfigProvider>
  </Router>

);
