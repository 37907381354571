import { createContext, PropsWithChildren, useEffect, useState } from "react";
import React from 'react';

export interface PromoData {
    promoId: string,
}

export interface PromoContextProps {
    promoData: PromoData,
    setPromoData: React.Dispatch<React.SetStateAction<PromoData>>,
}

export const promoContextDefaultValues: PromoContextProps = {
    promoData: { promoId: '0' },
    setPromoData: () => { }
}

export const PromoContext = createContext(promoContextDefaultValues);

export const PromoProvider = ({ children }: PropsWithChildren) => {

    // Initialize state with default values
    const [promoData, setPromoData] = useState<PromoData>(() => {
        // Retrieve persisted data from storage when component mounts
        const storedData = localStorage.getItem('promo');
        return storedData ? JSON.parse(storedData) : promoContextDefaultValues.promoData;
    });

    // Update state and persist data whenever it changes
    useEffect(() => {
        localStorage.setItem('promo', JSON.stringify(promoData));
    }, [promoData]);

    return (
        <PromoContext.Provider value={{ promoData, setPromoData }}>
            {children}
        </PromoContext.Provider>
    )
}