import { AxiosInstance, AxiosRequestConfig } from "axios";
import { apiErrorHandler } from "./errorHandler";
import * as Sentry from '@sentry/react'
import { HttpVerbs } from '../types/common';
import { UserData } from "../context/AuthContext";


const getHeaders = () => {
    const config:AxiosRequestConfig = {};
    const userDataText = localStorage.getItem('auth');
    if (userDataText) {
        try {
            const userData:UserData = JSON.parse(userDataText);
            if (userData.token) {
                config.headers = {
                    'Authorization': `Bearer ${userData.token}`
                }
            }
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    flow: 'load_user_data_localstorage',
                    user_data_text:userDataText,
                },
            });
            console.log('error al buscar token en localStorage', error)
        }
    }
    return config;
}

export const axiosRequest = async <T>(client:AxiosInstance, type:HttpVerbs, url:string, body?:T|any) => {
    try {
        const config = getHeaders();
        const { data } = await makeRequestByType<T>(client, type, url, body, config);
        return data;
    } catch (error) {
        return apiErrorHandler(error);
    }
}

const makeRequestByType = <T=any>(client:AxiosInstance, type:HttpVerbs, url:string, data:T, config:AxiosRequestConfig) => {
    switch (type) {
        case 'GET':
            return client.get(url, config);
        case 'POST':
            return client.post(url, data, config);
        default:
            return client.get(url, config);
    }
} 