import toast from "react-hot-toast";
import Toast from "../components/Toast";
import { ToastTypes } from "../types/common";

export const useToast = () => {
    const showToast = (type: ToastTypes, message: string) => {
        toast.custom(t => <Toast message={message} type={type} toastId={t?.id} />);
    }

    return { showToast }
}
