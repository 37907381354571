import { Outlet } from 'react-router-dom'

export default function Layout() {

  return (
    <div className={`min-h-screen flex flex-col bg-[#D4E5FF]`}>
      <Outlet />
    </div>
  )
}
