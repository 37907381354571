import React, { useRef, useState } from 'react';
import copys from '../utils/copys';
import IconExclamartionMark from './icons/IconExclamartionMark';
import { ConfigData } from '../context/ConfigContext';


const regex = /^[0-9]{0,10}$/;
const countryCode = 'MX';

const changeLabelChildrenStyles = (labelRef: HTMLDivElement, isFocused: boolean) => {
  if (labelRef && labelRef.children) {
    Array.from(labelRef.children).forEach(child => {
      const element = child as HTMLDivElement
      if (isFocused) {
        element.style.fontSize = '12px';
        element.style.lineHeight = '16px';
      } else {
        element.style.fontSize = '16px';
        element.style.lineHeight = '24px';
      }
    });
  }
}

type PhoneInputProps = {
  onChange: (value: string) => void;
  countryCodes: ConfigData[];
}

const PhoneInput = ({ onChange, countryCodes }: PhoneInputProps) => {

  const inputDivRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const labelRef = useRef<HTMLDivElement>(null)

  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);

  const phoneCode = '+' + countryCodes?.find(e => e.key === countryCode)?.value;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    if (value === phoneCode) {
      setInputValue('');
      onChange(value)
    } else {
      let phoneNumber = value.replace(`${phoneCode} `, '');
      if (regex.test(phoneNumber)) {
        setInputValue(`${phoneCode} ${phoneNumber}`);
        onChange(value)
      }
      if (hasError && phoneNumber.length === 10) {
        setHasError(false);
      }
    }
  };

  const handleOnFocus = () => {
    inputDivRef.current!.style.display = 'flex';
    changeLabelChildrenStyles(labelRef.current!, true);
    inputRef.current!.focus();
  }

  const handleOnBlur = () => {
    if (inputValue === '') {
      inputDivRef.current!.style.display = 'none';
      changeLabelChildrenStyles(labelRef.current!, false);
    } else {
      inputDivRef.current!.style.display = 'flex';
      if (inputValue.length < 14) {
        setHasError(true)
      }
    }
  }

  return (

    <div className='flex container flex-col items-center gap-1'>
      <div className="flex items-start container gap-1 justify-center">
        <div tabIndex={0} className={`rounded-lg border border-solid flex p-4 items-center justify-between gap-2 h-14 container basis-full min-[340px]:basis-11/12 focus-within:border-2 ${hasError ? 'border-border_danger' : 'border-border_primary'}`} onFocus={handleOnFocus} >
          <div className="flex items-center gap-2 justify-start">
            <div className="flex flex-col justify-center items-start">
              <div className="flex items-start gap-1 self-stretch" ref={labelRef}>
                <span className="text-white font-roboto text-base font-normal">
                  {copys.inputPhone}
                </span>
                <span className="text-text_secondary font-roboto font-normal text-base">
                  *
                </span>
              </div>
              <div className="items-start gap-1 self-stretch hidden" ref={inputDivRef}>
                <input
                  id={'inputPhone'}
                  ref={inputRef}
                  value={inputValue}
                  inputMode='numeric'
                  onChange={handleChange}
                  type="text"
                  className='text-white font-roboto text-base font-normal text-start'
                  onBlur={handleOnBlur}
                />
              </div>
            </div>
          </div>
          <div className={` w-6 h-6 shrink-0 ${hasError ? '' : 'invisible'}`}>
            <IconExclamartionMark fill='#FA7490' />
          </div>
        </div>
      </div>
      <div className="flex flex-col container items-center">
        <div id='inputPhoneErrorMessage' className={`flex justify-center text-left w-full font-roboto font-normal text-xs ${hasError ? 'text-border_danger' : 'text-white'}`}>
          <div className="basis-full min-[340px]:basis-11/12">
            {copys.inputPhoneErrorMessage}
          </div>
        </div>
      </div>
    </div>
  );
};



export default React.memo(PhoneInput);
