import React, { PropsWithChildren } from 'react'
import Button from './Button'

type CardPromoStatusProps = {
    buttonLabel: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    buttonStyle?: React.CSSProperties;
    labels?: {
        title?: string | JSX.Element;
        text?: string;
    }
}

const CardPromoStatus = ({ children, buttonLabel, onClick, buttonStyle, labels }: PropsWithChildren<CardPromoStatusProps>) => {
    const clickHandler = onClick ? onClick : () => { };
    const style = buttonStyle ? buttonStyle : {};
    const colorsClassNames = 'bg-primary_light text-white hover:bg-primary_light-hover active:bg-primary_light-active';
    return (
        <div className="px-4 py-8 rounded-xl flex flex-col justify-center items-center gap-4 bg-white w-full">
            {children}
            <div className="flex flex-col items-center gap-2">
                {
                    labels?.title && (
                        <div className="text-black text-center font-libre-franklin text-xl font-bold">
                            {labels.title}
                        </div>
                    )
                }
                {
                    labels?.text && (
                        <div className="text-text_strong text-center font-roboto text-base font-normal">
                            {labels.text}
                        </div>
                    )
                }

            </div>
            <Button id='detail' style={style} enabled label={buttonLabel} onClick={clickHandler} colorsClassName={colorsClassNames} />
        </div>
    )

}

export default React.memo(CardPromoStatus)
