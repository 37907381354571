export const getDiffBetweenDateTimes = (startDatetime:Date, endDatetime:Date) => {
    // Calculate the difference in milliseconds
    const timeDifference = endDatetime.getTime() - startDatetime.getTime();

    // Convert milliseconds to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference;
}

export const formatISOLocalDate = (date:Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
};

export const checkIfIsExpired = (expirationDate:string) =>{
    const promoEndDate = expirationDate.split('T')[0];
    const today = formatISOLocalDate(new Date());
    return promoEndDate < today;
}
