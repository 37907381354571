import React from 'react'
import { termsAndPrivacy } from '../../utils/copys'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { TermsOrPrivacy } from '../../utils/constants'

type ModalTermsProps = {
    onClickAccept: React.MouseEventHandler<HTMLButtonElement>,
    onClickReject: React.MouseEventHandler<HTMLButtonElement>,
}

const ModalTerms = ({ onClickAccept, onClickReject }: ModalTermsProps) => {
    return (
        <div className='bg-white rounded-xl flex flex-col py-8 px-4 justify-center items-center gap-2 w-4/5'>
            <div className='pt-5 font-libre-franklin text-base text-center'>
                <div className='font-bold'>{termsAndPrivacy.modal.text[0]}</div>
                <Link to={'/terms/' + TermsOrPrivacy.TERMS} className='font-bold text-[#3131DD] underline'>{termsAndPrivacy.modal.text[1]}</Link>
                <div>{termsAndPrivacy.modal.text[2]}</div>
            </div>
            <Button label={termsAndPrivacy.modal.buttonAccept} enabled onClick={onClickAccept} />
            <Button label={termsAndPrivacy.modal.buttonReject} enabled className={'bg-white'} onClick={onClickReject} />
        </div>
    )
}

export default ModalTerms
