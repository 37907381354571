import { OtpRetries } from "../context/AuthContext";
import { getDiffBetweenDateTimes } from "./dateUtils";

export const setOtpRetries = (phone:string, otpRetries:OtpRetries[]) => {
    let newOtpRetries:OtpRetries[];
    const currentOtpRetries = otpRetries?.find(e => e.phone === phone);
    const otherOtpRetries = otpRetries?.filter(e => e.phone !== phone);
    if (currentOtpRetries) {
        const hoursDiff = getDiffBetweenDateTimes(new Date(currentOtpRetries.datetime), new Date());
        if (hoursDiff < 24) {
            currentOtpRetries.tries = currentOtpRetries.tries + 1;
        } else {
            currentOtpRetries.tries = 1;
            currentOtpRetries.datetime = new Date().toISOString();
        }
        newOtpRetries = [currentOtpRetries, ...otherOtpRetries];
    } else {
        const newOtpRetry = {
            phone,
            datetime: new Date().toISOString(),
            tries: 1
        }
        newOtpRetries = [...otherOtpRetries, newOtpRetry]
    }
    return newOtpRetries;
}
