//Google Tag Manager service

window.dataLayer = window.dataLayer || [];

export const pushPageView = (pagePath: string, pageTitle: string) => {
    window.dataLayer.push({
        event: "page_view",
        pagePath,
        pageTitle,
    })
}

export const pushViewOrSelectPromotion = (isView: boolean, promotion_id: string, isLogged: boolean, promotion_name: string) => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: isView ? 'view_promotion' : 'select_promotion',
        ecommerce: {
            creative_name: "Promos " + (isLogged ? 'home cliente' : 'inicio'), // Este valor es fijo
            creative_slot: "Slider", // Este valor es fijo
            promotion_id,
            promotion_name,
            items: [
                {
                    item_name: "Producto genérico", // Este valor por ahora es fijo
                    item_id: "1", // Este valor por ahora es fijo
                    price: '00.00', // Este valor por ahora es fijo
                    item_brand: "Promos", // Este valor por ahora es fijo
                    item_category: "Cupones", // Este valor por ahora es fijo
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_variant: "",
                    location_id: isLogged ? '/cliente-home' : '/cliente-inicio',
                    creative_slot: "Slider", // Este valor es fijo
                    creative_name: "Promos " + (isLogged ? 'home cliente' : 'inicio'),// Este valor es fijo
                    promotion_id, // Id de la promoción. Más información en elapartado de Notas
                    promotion_name,
                    index: 1, // Este valor por ahora es fijo
                    quantity: 1 // Este valor por ahora es fijo
                }
            ]
        }
    });
}

export const pushClickButton = (label: string) => {
    window.dataLayer.push({
        event: "user_flow",
        category: "clic",
        action: "clic_boton",
        label
    });
}

export const pushEvent = (event: any) => {
    window.dataLayer.push(event)
}

export const pushNotification = (action: string, label: string) => {
    window.dataLayer.push({
        event: "user_flow",
        category: "system_message",
        action,
        label
    });
}
