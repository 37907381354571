import axios from 'axios';
import copys from './copys';
import { Response } from '../types/common';

export const apiErrorHandler = (error:any):Response<any> => {
    if (axios.isAxiosError(error)) {
        console.log('AxiosError', error);
        const errorResponse = error.response?.data;
        if (errorResponse) {
            return errorResponse;
        }
    }
    console.error(error)
    return { successful: false, message: copys.toastErrorMessageDefault, errors: [error], data: {} };
}