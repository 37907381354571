import React from 'react'
import { TailSpin } from 'react-loader-spinner'

type LoadingSpinnerProps = {
  width?: string | number,
  color?: string,
  strokeWidth?: string | number,
}

const LoadingSpinner = ({ width, color, strokeWidth }: LoadingSpinnerProps) => {
  return (
    <TailSpin
      visible={true}
      height={width}
      width={width}
      color={color}
      ariaLabel="tail-spin-loading"
      radius="1"
      strokeWidth={strokeWidth || 3}
      wrapperStyle={{}}
      wrapperClass=""
    />
  )
}

export default LoadingSpinner
