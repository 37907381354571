import Card from "../components/carousel/Card";
import ButtonBack from "../components/ButtonBack";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import copys from "../utils/copys";
import { TailSpin } from "react-loader-spinner";
import { ReactComponent as NotFavoriteIcon } from "../assets/ilustraciones/notFavorite.svg";
import { ReactComponent as FavoritesIcon } from "../assets/ilustraciones/favoritesShop.svg";

type Promotions = {
  shopkeeper_id: string;
  store_name: string;
  full_address: string;
  available_promotions: number;
  distance: number;
  promotions: [];
  favorites: boolean;
};

export const ScreenStoreDetails = () => {
  const [promotions, setPromotions] = useState<Promotions | null>(null);
  const { id } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const queryParams = new URLSearchParams(location.search);
  const latitude = queryParams.get("lat");
  const longitude = queryParams.get("lng");

  const userDataText = localStorage.getItem("auth");
  const userData = JSON.parse(userDataText as string);
  const { REACT_APP_AUTH_URL } = process.env;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchStoreData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_AUTH_URL}/v1/shopkeeper/detail/${id}?lat=${latitude}&lng=${longitude}`,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
            signal,
          }
        );

        setPromotions(response.data.data);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching store data:", error);
        }
        setLoading(false);
      }
    };

    fetchStoreData();

    return () => {
      controller.abort();
    };
  }, [REACT_APP_AUTH_URL, id, latitude, longitude, userData.token]);

  const backgroundClass = "#D4E5FF";

  const handleFavoriteAction = async () => {
    if (!promotions) return;

    const { shopkeeper_id, favorites } = promotions;

    try {
      const storeData: any = localStorage.getItem("auth");
      const userData = JSON.parse(storeData);
      const user_id = userData.user.id;

      if (favorites) {
        const response: any = await axios.delete(
          `${REACT_APP_AUTH_URL}/v1/consumer/favorites/${user_id}/${shopkeeper_id}`,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );

        if (response.data.successful) {
          const updatedPromotions: any = {
            ...promotions,
            favorites: !favorites,
          };

          setPromotions(updatedPromotions);
          return;
        }
      }

      if (!favorites) {
        setPromotions({
          ...promotions,
          favorites: !favorites,
        });
      }

      const response: any = await axios.post(
        `${REACT_APP_AUTH_URL}/v1/consumer/favorites`,
        {
          shopkeeper_id: promotions.shopkeeper_id,
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedPromotions: any = { ...promotions, favorites: !favorites };

        setPromotions(updatedPromotions);
      }
    } catch (error) {
      console.error("Error al actualizar favorito:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 self-stretch">
        <TailSpin
          visible={true}
          height="80"
          width="80"
          color="#FFB100"
          ariaLabel="tail-spin-loading"
          radius="1"
          strokeWidth={4}
        />
        <div className="font-roboto text-sm font-normal text-text_strong">
          {copys.promotions_available}
        </div>
      </div>
    );
  }

  if (!promotions) {
    return (
      <div className="flex flex-col items-center justify-center gap-2">
        <p>No data available.</p>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen flex flex-col justify-between ${backgroundClass} bg-center bg-cover bg-no-repeat`}
    >
      <ButtonBack customPage="/" customLabel={"Tiendas"} />

      <div className="store-card">
        <div className="store-header">
          <span className="distance">A {promotions.distance || 0} m</span>

          <div
            className={`favorites-icon-detail ${
              promotions.favorites ? "active" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleFavoriteAction();
            }} // Añadimos el evento de clic
          >
            {promotions.favorites ? <FavoritesIcon /> : <NotFavoriteIcon />}
          </div>
          <h2 className="store-name">{promotions.store_name || "Tienda"}</h2>

          <p className="store-address">{promotions.full_address}</p>
          <p className="store-discounts">
            {promotions.available_promotions} Descuentos disponibles
          </p>
        </div>

        <div className="discounts">
          <div className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-center items-center px-9 gap-3">
            {promotions.promotions.map((promo: any) => {
              return (
                <Card
                  key={promo.id}
                  promo={promo as any}
                  status={promo.status}
                  isImageOnly
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
