import { authClient } from "../../config/axios";
import { FormDataLogin } from "../../context/AuthContext";
import { StoresFiltersTypes } from "../../context/LocationContext";
import { RequestSendConfimationErrorShopkeeper } from "../../types/promotions";
import { RequestAcceptRejectTerms } from "../../types/terms-privacy";
import { axiosRequest } from "../../utils/axiosRequest";
import * as Sentry from "@sentry/react";

export const sendOtp = async (phone: string) => {
  try {
    const phone_number = phone.replace(" ", "");
    return await axiosRequest(authClient, "POST", "/v1/login/otp", {
      phone_number,
    });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        flow: "send_otp",
        phone_number: phone,
      },
    });
    console.log(error);
  }
};

export const validateOtp = async (phone: string, OTP: string) => {
  try {
    const phone_number = phone.replace(" ", "");
    const otp = Number(OTP);
    return await axiosRequest(authClient, "POST", "/v1/login/validate", {
      phone_number,
      otp,
    });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        flow: "validate_otp",
        phone_number: phone,
        otp: OTP,
      },
    });
    console.log(error);
  }
};

export const signUp = async (formData: FormDataLogin) => {
  try {
    return await axiosRequest(authClient, "POST", "/v1/signup", formData);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        flow: "send_otp",
        user_data: JSON.stringify(formData),
      },
    });
  }
};

export const acceptNewTerms = async (body: RequestAcceptRejectTerms) => {
  try {
    return await axiosRequest(
      authClient,
      "POST",
      "/v1/consumer/terms-conditions",
      body
    );
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        flow: "accept_new_terms",
        data: JSON.stringify(body),
      },
    });
  }
};

export const sendErrorReceivedConfirmation = async (
  body: RequestSendConfimationErrorShopkeeper
) => {
  try {
    return await axiosRequest(authClient, "POST", `/v1/coupon/`, body);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        flow: "confirm_error_received",
        data: JSON.stringify(body),
      },
    });
  }
};

export const getStoresNearBy = async (
  latitude: string,
  longitude: string,
  selectedStoreFilters: StoresFiltersTypes[]
) => {
  try {
    const isFavoritesOnly = selectedStoreFilters.includes("FAVORITE");

    const body = {
      latitude,
      longitude,
      favorite: isFavoritesOnly,
      channel: "",
    };

    if (!isFavoritesOnly && selectedStoreFilters.length === 1) {
      body.channel = selectedStoreFilters[0];
    }

    return await axiosRequest(
      authClient,
      "POST",
      `/v1/shopkeeper/nearby`,
      body
    );
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        flow: "get_stores_nearby",
        data: JSON.stringify({ latitude, longitude }),
      },
    });
  }
};
