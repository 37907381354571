import logoImg from '../assets/img/logo.png';

type LogoProps = {
  width: number,
}

export function Logo({ width }: LogoProps) {

  return (
    <div className='flex flex-col justify-center items-center'>
      <img src={logoImg} width={width} alt="Logo" />
    </div>
  )
}
