import { couponsClient } from '../config/axios';
import { LocationData } from '../models/location';
import { axiosRequest } from '../utils/axiosRequest';

export const getPromos = async (location: LocationData | null) => {
    const params = location === null ? '' : `?lat=${location.latitude}&lng=${location.longitude}`;
    return await axiosRequest(couponsClient, 'GET', `/v1/coupon${params}`);
}

export const getPromoById = async (promoId: string) => {
    return await axiosRequest(couponsClient, 'GET', '/v1/coupon/' + promoId);
}

export const activatePromo = async (promotion_id: string) => {
    return await axiosRequest(couponsClient, 'POST', '/v1/coupon/activate', { promotion_id });
}
