import React from 'react'
import { useLocationContext } from '../../hooks/useLocationContext';
import { ReactComponent as Map } from '../../assets/icons/map.svg'
import { ReactComponent as UserHand } from '../../assets/icons/user-hand.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import IconLocation from '../../components/icons/IconLocationHome'

const DisclaimerLocation = () => {

  const { getLocation } = useLocationContext();

  const handleButtonClick = async () => {
    await getLocation();
  }

  return (
    <div className='w-full px-4'>
      <div className='bg-white rounded-3xl p-10 mt-4 flex justify-center items-center font-roboto text-[#070561]'>
        <div className='flex flex-col gap-2'>
          <div className='text-base font-semibold'>Obten beneficios con tu ubicación:</div>
          <div className="flex gap-2 items-center">
            <Map />
            <div className='text-sm'>Mapa de lugares dónde canjear</div>
          </div>
          <div className="flex gap-2 items-center">
            <UserHand />
            <div className='text-sm'>Acceso a todas las promociones</div>
          </div>
          <div className="flex gap-2 items-center">
            <Star />
            <div className='text-sm'>Canjes al instante</div>
          </div>
          <div onClick={handleButtonClick} className='flex items-center gap-[10px] mt-8 py-5 px-10 bg-[#100E9D] rounded-xl cursor-pointer'>
            <IconLocation size={25} />
            <div className='text-white font-semibold'>
              Autorizar ubicación
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisclaimerLocation;
