import React, { useEffect, useState } from 'react'
import { Logo } from '../components/Logo'
import ButtonBack from '../components/ButtonBack'
import { useParams } from 'react-router-dom'
import { ToastTypesList } from '../utils/constants'
import copys, { termsAndPrivacy } from '../utils/copys'
import { getPrivacyPolicies, getTermsAndConditions } from '../services/commonService'
import { useToast } from '../hooks/useToast'
import parse from 'html-react-parser';
import * as gtmService from '../services/gtmService'
import * as Sentry from '@sentry/react'
import { TermsOrPrivacy } from '../types/common'

const ScreenTermsAndPrivacy = () => {
    const params = useParams();
    const type = params.type as TermsOrPrivacy;
    const [content, setContent] = useState('');
    const { showToast } = useToast();


    useEffect(() => {
        const termsOrPrivacy = type === 'terms' ? 'terminos' : 'politicas';
        gtmService.pushPageView(`/cliente-${termsOrPrivacy}`, `Cliente: ${termsOrPrivacy.split('')[0].toUpperCase() + termsOrPrivacy.slice(1)}`)
        const getContent = async (contentType: TermsOrPrivacy) => {
            let response;
            if (contentType === 'terms') {
                response = await getTermsAndConditions();
            } else {
                response = await getPrivacyPolicies();
            }
            const { successful, data, message, errors } = response;
            if (successful) {
                setContent(data.content);
            } else {
                Sentry.captureException(errors, {
                    tags: {
                        flow: contentType === 'terms' ? 'load_app_terms' : 'load_app_privacy',
                    },
                });
                showToast(ToastTypesList.ERROR, copys.toastErrorMessageDefault);
                console.log(errors);
                gtmService.pushNotification('error', message)
            }
        }

        getContent(type);
    }, [])
    return (
        <div className='flex flex-col items-start'>
            <div className='py-3 px-4'>
                <Logo width={65} />
            </div>
            <ButtonBack />
            <div className="flex flex-col py-8 px-4 gap-4 text-text_strong">
                <div className='font-libre-franklin text-3xl font-bold'>
                    {type === 'terms' ? termsAndPrivacy.termsFooterLabel : termsAndPrivacy.privacyFooterLabel}
                </div>
                <div className='font-roboto text-base font-normal '>
                    {parse(content)}
                </div>
            </div>
        </div>
    )
}

export default ScreenTermsAndPrivacy
