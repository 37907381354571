import React from 'react'

type AddressPromptProps = {
    title: string;
    detail: string;
    onClick: () => void;
}
const AddressPrompt = ({ title, detail, onClick }: AddressPromptProps) => {

    return (
        <div className='flex flex-col items-start w-full px-6 py-5 gap-1 font-libre-franklin text-xs cursor-pointer active:bg-[#D4E5FF]' onClick={onClick}>
            <div className='text-[#070561] font-bold'>{title}</div>
            <div className='text-[#95A2AD] font-semibold'>{detail}</div>
        </div>
    )
}

export default AddressPrompt
