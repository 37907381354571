import React from 'react'

type IconLocationHomeProps = {
    size:number,
}
const IconLocationHome = ({size}:IconLocationHomeProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 17 16" fill="none">
    <g clipPath="url(#clip0_11978_131333)">
        <path
            d="M12.4997 6.66732C12.4997 5.25283 11.9378 3.89628 10.9376 2.89608C9.93738 1.89589 8.58083 1.33398 7.16634 1.33398C5.75185 1.33398 4.3953 1.89589 3.3951 2.89608C2.39491 3.89628 1.83301 5.25283 1.83301 6.66732C1.83301 9.99598 5.52567 13.4627 6.76567 14.5333C6.88125 14.62 7.02187 14.6668 7.16634 14.6667M12.4997 14.6673V12.6673M10.4997 14.6673C10.3229 14.6673 10.1533 14.5971 10.0283 14.4721C9.90325 14.347 9.83301 14.1775 9.83301 14.0007V11.334C9.83299 11.2243 9.86006 11.1162 9.91181 11.0195C9.96356 10.9227 10.0384 10.8402 10.1297 10.7793L12.1297 9.44598C12.2392 9.37289 12.368 9.33388 12.4997 9.33388C12.6314 9.33388 12.7601 9.37289 12.8697 9.44598L14.8697 10.7793C14.961 10.8402 15.0358 10.9227 15.0875 11.0195C15.1393 11.1162 15.1664 11.2243 15.1663 11.334V14.0007C15.1663 14.1775 15.0961 14.347 14.9711 14.4721C14.8461 14.5971 14.6765 14.6673 14.4997 14.6673H10.4997ZM9.16634 6.66732C9.16634 7.77189 8.27091 8.66732 7.16634 8.66732C6.06177 8.66732 5.16634 7.77189 5.16634 6.66732C5.16634 5.56275 6.06177 4.66732 7.16634 4.66732C8.27091 4.66732 9.16634 5.56275 9.16634 6.66732Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
        <clipPath id="clip0_11978_131333">
            <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
    </defs>
</svg>
  )
}

export default IconLocationHome
