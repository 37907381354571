import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import PhoneInput from "../components/PhoneInput";
import copys from '../utils/copys'
import { useCallback, useEffect, useState } from "react";
import { sendOtp } from "../services/authService/authService";
import { Logo } from "../components/Logo";
import { useAuthContext } from "../hooks/useAuthContext";
import toast from "react-hot-toast";
import Toast from "../components/Toast";
import { routePaths, ToastTypesList } from "../utils/constants";
import { setOtpRetries } from "../utils/otpRetries";
import LoadingSpinner from "../components/LoadingSpinner";
import * as gtmService from '../services/gtmService';
import { useConfigContext } from "../hooks/useConfigContext";
import * as Sentry from '@sentry/react'

export default function ScreenLogin() {

    const [phone, setPhone] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const navigate = useNavigate();
    const { userData, setUserData } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const { countryCodes } = useConfigContext();

    const getPhoneValue = useCallback((value: string) => {
        setPhone(value);
    }, [setPhone]);

    useEffect(() => {
        if (userData.isLogged) {
            navigate('/');
        }
        gtmService.pushPageView('/cliente-login', 'Cliente: Login');
    }, [])

    useEffect(() => {
        if (phone.length < 14) {
            setButtonEnabled(false);
        } else {
            setButtonEnabled(true);
        }
    }, [phone])

    const handleClick = async () => {
        if (phone.length < 14) {
            return;
        }
        gtmService.pushEvent({ userID: `UID${phone.replace('+52 ', '')}` })
        gtmService.pushClickButton(copys.labelBtnLogin);
        setIsLoading(true);
        const { successful, message, errors } = await sendOtp(phone);
        setIsLoading(false)
        if (successful) {
            const newOtpRetries = setOtpRetries(phone, userData.otpRetries!);
            setUserData({ ...userData, phoneToValidate: phone, otpRetries: newOtpRetries, loginOrRegister: 'login' });
            navigate('/' + routePaths.OTP)
        } else {
            Sentry.captureException(errors, {
                tags: {
                    flow: 'send_otp',
                    phone_number: phone,
                },
            });
            toast.custom(t => (<Toast message={message} type={ToastTypesList.ERROR} toastId={t.id} />))
            console.log('error in sending otp', errors);
        }
    }


    return (
        <div className="pt-10 h-screen bg-money-image bg-cover bg-center flex flex-col items-center">
            <div className="container p-3 text-center min-[480px]:w-[480px]">
                <div className="my-3 mb-5">
                    <Logo width={160} />
                </div>
                <div className="my-3 px-10">
                    <div className="text-white font-libre-franklin text-3xl font-bold" id='loginScreenMessage'>{copys.welcomeMessage}</div>
                </div>
                <div className="mb-10 px-10">
                    <div className="text-xs font-roboto text-white">
                        {copys.disclaimerCalls}
                    </div>
                </div>
                <div className="my-3 container">
                    <PhoneInput onChange={getPhoneValue} countryCodes={countryCodes!} />
                </div>
                <div className="my-4 px-5 text-center flex justify-center">
                    <Button id={'continue'} label={copys.labelBtnLogin} enabled={buttonEnabled} onClick={handleClick} isLoading={isLoading} icon={isLoading ? { icon: <LoadingSpinner width={20} color={'#0C0D0F'} />, position: 'end' } : undefined} />
                </div>
                <div className="my-5 text-white text-center text-sm font-roboto">
                    <span id='dontHaveAccountText'>{copys.dontHaveAccount}</span>
                    <span className="text-base font-semibold"> <Link id="registerLink" to={'/signup'}>{copys.labelRegister}</Link></span>
                </div>
            </div>
        </div>
    )
}
