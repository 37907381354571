import { miscClient } from '../config/axios';
import { axiosRequest } from '../utils/axiosRequest';

export const getConfiguration = async () => {
    return await axiosRequest(miscClient, 'GET', '/v1/general_config/consumer');
}

export const getPrivacyPolicies = async () => {
    return await axiosRequest(miscClient ,'GET', '/v1/content/consumer/policies_privacy')
}

export const getTermsAndConditions = async () => {
    return await axiosRequest(miscClient, 'GET', '/v1/content/consumer/terms_conditions')
}
