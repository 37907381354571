import axios from 'axios'

export const getAddressFromCoords = async (lat: number, lng: number): Promise<string | null> => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const baseUrl = "https://maps.googleapis.com/maps/api/geocode/json";

    try {
        const response = await axios.get(baseUrl, {
            params: {
                latlng: `${lat},${lng}`,
                key: apiKey,
                lang:'es-419'
            },
        });

        if (response.data.status === "OK") {
            console.log(response.data)
            const address = response.data.results[0]?.formatted_address;
            return address || null;
        } else {
            console.error("Geocoding API error:", response.data.status);
            return null;
        }
    } catch (error) {
        console.error("Error fetching address:", error);
        return null;
    }
};
