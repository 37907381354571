import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import copys from '../utils/copys';
import Button from '../components/Button'
import { validateOtp } from '../services/authService/authService';
import { Logo } from '../components/Logo'
import { useAuthContext } from '../hooks/useAuthContext';
import { otpValidationStatus, routePaths, ToastTypesList } from '../utils/constants';
import { sendOtp } from '../services/authService/authService'
import OtpInput from '../components/OtpInput'
import { usePromoContext } from '../hooks/usePromoContext';
import { setOtpRetries } from '../utils/otpRetries';
import LoadingSpinner from '../components/LoadingSpinner';
import * as gtmService from '../services/gtmService';
import { useToast } from '../hooks/useToast';
import * as Sentry from '@sentry/react'
import { HandleOtpChangeParam } from '../types/common';

export default function ScreenOTP() {

    const navigate = useNavigate();
    const { userData, setUserData } = useAuthContext();
    const { promoData } = usePromoContext();
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [isOtpWithErrors, setIsOtpWithErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [otpValue, setOtpValue] = useState<string>();
    const [resendDisabled, setResendDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showToast } = useToast();


    const validateResendDisabled = () => {
        const otpRetry = userData.otpRetries?.find(e => e.phone === userData.phoneToValidate);
        if (otpRetry) {
            if (otpRetry.tries >= 2) {
                setResendDisabled(true)
            }
        }
    }

    useEffect(() => {
        if (userData.isLogged) {
            navigate('/');
        }
        validateResendDisabled()
        const loginOrRegister = userData.loginOrRegister;
        gtmService.pushPageView(`/cliente-otp-${loginOrRegister}`, `Cliente: OTP ${loginOrRegister}`)
    }, [])

    useEffect(() => {
        if (userData.isLogged) {
            if (promoData.promoId !== '0') {
                navigate(`/${routePaths.DETAIL.replace(':promoId', promoData.promoId)}`);
            } else {
                navigate('/' + routePaths.HOME);
            }
        }
    }, [userData])

    const validateOTP = async (otp: string) => {
        gtmService.pushClickButton(copys.labelBtnContinuar);
        setIsLoading(true)
        const { successful, message, errors, data } = await validateOtp(userData.phoneToValidate!, otp);
        setIsLoading(false);
        if (successful) {
            gtmService.pushEvent({
                event: "login",
                method: 'Numero telefonico'
            })
            const { id, birth_year, name, gender } = data.user.userFounded;
            const newUserData = {
                token: data.token,
                user: {
                    id,
                    birth_year,
                    name,
                    gender,
                }
            }
            setUserData({ ...newUserData, isLogged: true });
            showToast(ToastTypesList.SUCCESS, copys.toastLoginSuccessful)
            gtmService.pushNotification('success', copys.toastLoginSuccessful)
        } else {
            setButtonEnabled(false);
            setIsOtpWithErrors(true)
            errors.forEach((e: any) => {
                setErrorMessage(message);
                switch (e.code) {
                    case otpValidationStatus.INCORRECT_OTP:
                        console.log({ message: e.detail, code: e.code })
                        break;
                    case otpValidationStatus.EXPIRED_OTP:
                        console.log({ message: e.detail, code: e.code })
                        break;
                    case otpValidationStatus.PHONE_NOT_FOUND:
                        console.log({ message: e.detail, code: e.code })
                        break;
                    case otpValidationStatus.USER_NOT_REGISTERED:
                        gtmService.pushEvent({
                            event: "sign_up",
                            method: 'Numero telefonico'
                        })
                        navigate('/' + routePaths.REGISTER)
                        break;
                    default:
                        Sentry.captureException(e, {
                            tags: {
                                flow: 'validate_otp',
                                phone_number: userData.phoneToValidate,
                                otp
                            },
                        });
                        console.log({ message: e.detail, code: e.code })
                        break;
                }
            })
        }
    }

    const handleOtpChange = ({ isComplete, otp }: HandleOtpChangeParam) => {
        setOtpValue(otp)
        if (isComplete) {
            setButtonEnabled(true);
            validateOTP(otp);
        } else {
            setIsOtpWithErrors(false);
            setButtonEnabled(false);
        }
    }

    const resendCode = async () => {
        gtmService.pushClickButton(copys.labelResendOTP);
        const { successful, message } = await sendOtp(userData.phoneToValidate!);
        if (successful) {
            const newOtpRetries = setOtpRetries(userData.phoneToValidate!, userData.otpRetries!);
            setUserData({ ...userData, otpRetries: newOtpRetries });
            validateResendDisabled()
        } else {
            console.log(message);
        }
    }

    return (
        <div className="pt-10 h-screen bg-money-image bg-cover bg-center flex flex-col items-center">
            <div className="container p-2 min-[480px]:w-[480px]">
                <div className="m-2 mb-5">
                    <Logo width={130} />
                </div>
                <div className="my-5">
                    <div className='text-base font-roboto text-white text-center' id='labelIngressOtp'>
                        {copys.labelIngressOTPDetail}
                    </div>
                </div>
                <div className="my-5">
                    <OtpInput
                        length={6}
                        onChange={handleOtpChange}
                        isOtpWithErrors={isOtpWithErrors}
                        errorMessage={errorMessage}
                    />
                </div>
                <div className="mb-2 text-white font-roboto text-[10px] pt-5" id='disclaimerOtp'>
                    {copys.labelDisclaimerResendOTP}
                </div>
                <div className="mb-2">
                    <button id='resendCodeButton' disabled={resendDisabled} className={`flex justify-center items-center gap-1 py-2 px-4 text-center font-roboto text-base font-semibold ${resendDisabled ? 'text-text_disabled' : 'text-white'}`} onClick={resendCode} >{copys.labelResendOTP}</button>
                </div>
                <div className="mb-2 text-center flex justify-center">
                    <Button id={'continue'} label={copys.labelBtnContinuar} enabled={buttonEnabled} onClick={() => validateOTP(otpValue || '')} isLoading={isLoading} icon={isLoading ? { icon: <LoadingSpinner width={20} color={'#0C0D0F'} />, position: 'end' } : undefined} />
                </div>
            </div>
        </div>
    )
}
