import React, { createContext, PropsWithChildren, useEffect, useState } from "react";

export interface User {
    id: string;
    birth_year: string;
    name: string;
    gender: 'M' | 'F' | 'O';
}

export interface OtpRetries {
    phone: string,
    datetime: string,
    tries: number,
}

export interface UserData {
    isLogged: boolean,
    otpRetries?: OtpRetries[],
    phoneToValidate?: string,
    token?: string,
    user?: User,
    loginOrRegister?: 'login' | 'registro',
    navigatedFromHome?: boolean,
}

export interface FormDataLogin {
    name: string,
    birth_year: string | null,
    gender: string | null,
    phone_number?: string,
    isAccepted: boolean,
}

interface AuthContextProps {
    userData: UserData,
    setUserData: React.Dispatch<React.SetStateAction<UserData>>,
    formData: FormDataLogin,
    setFormData: React.Dispatch<React.SetStateAction<FormDataLogin>>,
}

const initialValue: AuthContextProps = {
    userData: {
        isLogged: false,
        otpRetries: []
    },
    setUserData: () => { },
    formData: {
        name: '',
        birth_year: null,
        gender: null,
        isAccepted: false,
    },
    setFormData: () => { },
}

export const AuthContext = createContext(initialValue);

export const AuthProvider = ({ children }: PropsWithChildren) => {

    // Initialize state with default values
    const [userData, setUserData] = useState<UserData>(() => {
        // Retrieve persisted data from storage when component mounts
        const storedData = localStorage.getItem('auth');
        return storedData ? JSON.parse(storedData) : initialValue.userData;
    });

    const [formData, setFormData] = useState<FormDataLogin>({
        name: '',
        birth_year: null,
        gender: null,
        phone_number: userData.phoneToValidate?.replace(' ', ''),
        isAccepted: false,
    })

    // Update state and persist data whenever it changes
    useEffect(() => {
        localStorage.setItem('auth', JSON.stringify(userData));
    }, [userData]);

    return (
        <AuthContext.Provider value={{ userData, setUserData, formData, setFormData }}>
            {children}
        </AuthContext.Provider>
    )
}