import { useContext } from "react";
import { LocationContext } from "../context/LocationContext";

export const useLocationContext = () => {
    const context = useContext(LocationContext);
    if (!context) {
        throw new Error('usePromoContext must be used within a MyProvider');
    }
    return context;
}
